import debug from 'debug';

// Import various polyfills (for IE browsers mainly)
// Most polyfills come from core-js,, see a list of all possible features on https://github.com/zloirock/core-js/tree/v2#features
import 'core-js/features/promise';	// Promises is vital for alot of the logic, so we always make sure to include it

const log = debug('Polyfills');

if (!window.fetch) {
	log('loading', 'whatwg-fetch');
	import('whatwg-fetch');
}

// Javascript test for IE browser, as we only include the polyfills in IE
if ( navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1 ) {
	// Microsoft Internet Explorer detected in. 
	log('loading', 'core-js/es6');
	import('../../../../../../node_modules/core-js');
}

// .append polyfill
if (!Element.prototype.hasOwnProperty('append') || !Document.hasOwnProperty('append') || !DocumentFragment.hasOwnProperty('append') ) {
	log('loading', 'append');
	import('./parts/append');
}

// .remove polyfill
if (!Element.prototype.hasOwnProperty('remove'), !CharacterData.prototype.hasOwnProperty('remove'), !DocumentType.prototype.hasOwnProperty('remove') ) {
	log('loading', 'remove');
	import( './parts/remove');
}

// CustomEvent
if (typeof window.CustomEvent !== "function") {
	log('loading', 'CustomEvent')
	import ('./parts/CustomEvent');
}

// DOM node closest
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
	if (!document.documentElement.contains(el)) return null;
	do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
	} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if('objectFit' in document.documentElement.style === false) {
	log('loading', 'object-fit');
	import('./parts/object-fit');
}
