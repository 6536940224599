import debug from 'debug';
import "regenerator-runtime/runtime";
import '../../Shared/Components/Polyfills/Polyfills';

const log = debug('ModuleLoader');

export function initComponents({ context = document } = {}) {

	if (context !== document) {
		log('Initializing on ', context);
	}
	
	let componentReferences = context.querySelectorAll('[data-component]');

	for (var i = 0; i < componentReferences.length; i++) {
		(function(element) {
			const moduleName = element.attributes['data-component'].value;
			
			/**
			 * Dynamic imports
			 * 
			 * Using webpack magic comments (https://webpack.js.org/api/module-methods/#magic-comments) to exclude files we are never going to import
			 *  .scss Sass source files
			 *  .test.js Test files
			 *  .snap Snapshot files usded in unit testing
			 */
			import(
				/* webpackExclude: /.scss|.snap|.test.js$/ */
				`../../Shared/Design101/8-organisms/${moduleName}/${moduleName}`
			).then(function(ComponentConstructor) {
				
				log(`loaded ${moduleName}`, element);

				if(typeof ComponentConstructor.default === "function") {
				try {
					element.component = new ComponentConstructor.default(element);
				} catch(err) {
					log('Error', moduleName, moduleName, err)
				}				
				}
			}).catch(function(err) {
				log(`Failed to load ${moduleName}`, err);
			});
		
		})(componentReferences[i]);
	}
}

initComponents();

